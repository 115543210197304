import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import Showdown from 'showdown'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import YouTube from '../components/productYouTube'
// import AppStore from '../components/appStore'
import Related from '../components/related'
// import Faqs from '../components/faqs'
import Cta from '../components/cta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const ProductTemplate = ({ data }) => {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  const converter = new Showdown.Converter()   
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={heroImage?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg={ post.frontmatter.headline && post.frontmatter.headline.length >= 1 ? '8' : '12' } >
              <div
                className="font-w-400 text-xs-extra-large divlink text-opening-para"
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
              />
                <div
                  className="richtext divlink mt-3"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
              {post.frontmatter.headline &&
                post.frontmatter.headline.length >= 1 && (
                  <MDBCol lg="4" md="12" className="pl-lg-5">
                    {post.frontmatter.headline.map((headlines, index) => (
                      <div className="headline-tile mt-lg-3 item-headline" key={index} >
                        <div className="headline-icon">
                          <FontAwesomeIcon
                            icon={['fat', headlines.icon]}
                            size="2x"
                            className="fa-fw"
                          />
                        </div>
                        <div className="headline-content">
                          <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium">
                            {headlines.title}
                          </h3>
                          <div className="headline-action font-alt letter-spacing-1 text-xs-medium text-small">
                            {headlines.resource ? (
                              <a href={headlines.resource.publicURL} className="effect-noline" target="_blank" rel="noopener" > {headlines.linktext} <FontAwesomeIcon icon={faChevronRight} className="ml-2" /> </a>
                            ) : headlines.link.charAt(0) != '/' ? (
                              <a href={headlines.link} className="effect-noline" target="_blank" rel="noopener" > {headlines.linktext} <FontAwesomeIcon icon={faChevronRight} className="ml-2" /> </a>
                            ) : (
                              <Link to={headlines.link} className="effect-noline" > {headlines.linktext} <FontAwesomeIcon icon={faChevronRight} className="ml-2" /> </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* <Link
                      to="/contact/"
                      className="nav-link btn btn-sm-block btn-mdb-color mb-2 mb-md-4 mt-md-1"
                    >
                      {' '}
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                      Request a demo
                    </Link> */}
                  </MDBCol>
                )}
            </MDBRow>
          </MDBContainer>
        </section>
          
        {post.frontmatter.section.map((sections, index) => (
          <section className="bg-light-blue-prods" key={index}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="4" className="pr-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {sections.title}
                  </h2>
                  <p className="text-medium text-gray-dark mt-3">
                    {sections.description}
                  </p>
                </MDBCol>

                <MDBCol md="8">
                  <MDBRow>
                    {sections.feature.map((features, index) => (
                      <MDBCol md="6" className="pb-5 item-prods" key={index}>
                        <div className="feature-icon">
                          <FontAwesomeIcon
                            icon={['fat', features.icon]}
                            size="3x"
                          />
                        </div>
                        <p className="text-medium text-blue-dark font-w-700">
                          {features.title}
                        </p>
                        <p className="text-medium text-gray-dark">
                          {features.description}
                        </p>
                        {features.link !== null && (
                          <p className="text-small">
                            <Link to={features.link} className="effect">
                              {features.linktext}
                            </Link>
                          </p>
                        )}
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        ))}

        {post.frontmatter.videos &&
          <section className="bg-white" id="videos">
            <MDBContainer>
              {post.frontmatter.videos.title &&
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large text-center pb-5">
                  {post.frontmatter.videos.title}
                </h2>
              }

              {post.frontmatter.videos.video &&
                <div className="justify-content-center text-center">
                  {post.frontmatter.videos.video.length == 1 ? 
                    <>
                      {post.frontmatter.videos.video.map((video, index) => (
                        <MDBRow className="align-items-top justify-content-center text-center" key={index}>
                          <MDBCol lg="6" className="mt-4 mt-lg-0 mb-4 mb-lg-0" >
                            <YouTube data={video} />
                          </MDBCol>
                        </MDBRow>
                      ))}
                    </>
                  :
                    <MDBRow className="align-items-top justify-content-center">
                      {post.frontmatter.videos.video.map((video, index) => (
                        <MDBCol lg="6" className="mt-4 mt-lg-0 mb-4 mb-lg-0" key={index} >
                          <YouTube data={video} />
                        </MDBCol>
                      ))}
                    </MDBRow>                 
                  }

                  {(post.frontmatter.videos.link && post.frontmatter.videos.linktext) &&
                    <Link
                      to={post.frontmatter.videos.link}
                      className="nav-link btn btn-sm-block btn-mdb-color mb-2 mb-md-4 mt-md-5"
                    >
                      {' '}
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                        {post.frontmatter.videos.linktext}
                    </Link>
                }
                </div>
              }
          </MDBContainer>
        </section>
        }

        {/* {post.frontmatter.stores && 
          <AppStore data={post.frontmatter.stores} />
        }  */}

        {/* {post.frontmatter.faq && post.frontmatter.faq.length >= 1 && (
          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="pb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium">
                    Frequently asked questions (FAQ)
                  </h2>
                </MDBCol>
              </MDBRow>

              {post.frontmatter.faq && <Faqs items={post.frontmatter.faq} />}
            </MDBContainer>
          </section>
        )} */}

        {(post.frontmatter.related && post.frontmatter.related.length > 0) && (  
          <section className="bg-gray-light">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium">
                    Related products, solutions and news
                  </h2>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                {post.frontmatter.related.map((relate, index) => (
                    <Fragment key={index}>
                    {(relate.document || relate.link) &&
                      <Related
                        key={index}
                        collg="4"
                        colmd="6"
                        height="8rem"
                        type={relate.type}
                        title={relate.title}
                        description={relate.description}
                        linktext={relate.linktext}
                        link={ relate.document ? relate.document.publicURL : relate.link }
                      />
                    }
                    </Fragment>
                  )
                )}
              </MDBRow>
            </MDBContainer>
          </section>
        )}

        {post.frontmatter.cta && (
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        )}
      </Layout>
    </div>
  )
}
export default ProductTemplate

export function Head({ data, location }) {
  const heroImage = data.heroImage
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.title}
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark( fields: { slug: { eq: $slug } } frontmatter: { template: { eq: "products" } } ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        subtitle
        ctatext
        ctaslug        
        openingpara
        headline {
          title
          icon
          description
          linktext
          link
          resource {
            publicURL
          }
        }
        videos {
          title
          linktext
          link
          video {
            title
            subtitle
            intro
            link
          }
        }
        stores {
          title
          subtitle
          store {
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            alttext
            link
          }
        }
        section {
          title
          description
          feature {
            title
            icon
            description
            linktext
            link
          }
        }
        related {
          type
          title
          description
          linktext
          link
          document {
            publicURL
          }
        }
        faq {
          question
          answer
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
